import React from "react"
import Layout from "../../components/layout"
import Hero from "../../components/hero"
import { Helmet } from "react-helmet"
import Img from "../../assets/images/bgHeroBusesUrbanos.jpg"
import { BusInterprovincial } from "../../data/buses"
import BlockBusInterprovincial from "../../components/buses/blockTpBusesDesc"
export default function BusesInterprovinciales() {
  return (
    <>
      <Helmet>
        <title>Buses Interprovinciales</title>
        <meta
          name="description"
          content="Buses Interprovinciales de Intramet"
        ></meta>
      </Helmet>
      <Layout>
        <Hero img={Img} title={BusInterprovincial.title}></Hero>
        <BlockBusInterprovincial
          data={BusInterprovincial}
        ></BlockBusInterprovincial>
      </Layout>
    </>
  )
}
